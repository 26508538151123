import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { map, Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { ApiUser, User as RmUser } from 'rm-api-services/dist/api-services';
import { environment } from 'environments/environment';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: User;
  rmUser: RmUserApp;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _apiUser: ApiUser
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getUserData();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  private getUserData(): void {
    this.rmUser = {
      name: '',
      email: '',
    };
    if (localStorage.getItem('user_name') === null) {
      const userId = localStorage.getItem('user_id');

      this._apiUser
        .getById(userId)
        .pipe(map((res) => res.result))
        .subscribe((user: RmUser) => {
          localStorage.setItem('user_name', user.displayName);
          localStorage.setItem('user_email', user.email);

          this.rmUser = {
            name: user.displayName,
            email: user.email,
          };
          this._changeDetectorRef.detectChanges();
        });
    } else {
      this.rmUser = {
        name: localStorage.getItem('user_name'),
        email: localStorage.getItem('user_email'),
      };
    }
  }
}

interface RmUserApp {
  name: string;
  email: string;
}
