<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Teleconference</h3>

  <!-- select the option take action -->
  <div class="mt-5 max-w-[450px]">
      <p class="text-neutral-100 mt-3">
        Start a teleconference call.
      </p>
  </div>

  <!-- <div class="video-wrapper relative">
    <video
      #remoteVideo
      id="remote-video"
      autoplay
      playsinline
    ></video>

    <video class="absolute top-2 right-2"
      #localVideo
      id="local-video"
      autoplay
      playsinline
    ></video>
  </div>
  <div class="flex justify-between items-center">
    <button (click)="toggleVideo(!closeVideo)">
      <mat-icon *ngIf="!closeVideo" class="mr-2" svgIcon="mat_solid:videocam_off"></mat-icon>
      <mat-icon *ngIf="closeVideo" class="mr-2" svgIcon="mat_solid:videocam"></mat-icon>
    </button>
    <button (click)="endCall(false, incomingCall)">
      <mat-icon  class="mr-2" svgIcon="mat_solid:call_end"></mat-icon>
    </button>
    <button (click)="toggleAudio(!closeAudio)">
      <mat-icon *ngIf="!closeAudio" class="mr-2" svgIcon="mat_solid:volume_off"></mat-icon>
      <mat-icon *ngIf="closeAudio" class="mr-2" svgIcon="mat_solid:volume_up"></mat-icon>
    </button>
  </div> -->
</div>

