import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import { Mission, MqttSettings, Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subject, Subscription, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-teleconference',
  templateUrl: './teleconference.component.html',
  styleUrls: ['./teleconference.component.scss']
})
export class TeleconferenceComponent implements OnInit, OnDestroy {

  @ViewChild('bottomOfJobCreation', { static: false })
  bottomOfJobCreationRef: ElementRef;

  @Input() layoutId: string;
  @Input() events: Observable<void>;
  @Input() skillId: string;
  @Input() robot: Robot;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();

  filteredOptions: Observable<SelectOption[]>;

  schedule: string;
  selectedStartJob: number;

  isBlinkingLightsCheck: boolean;
  blinkingLightsSkill: Skill;
  taskBlinkingLights;
  taskBlinkingLightsOff;
  isBroadcastMessageCheck: boolean;
  broadcastMessageSkill: Skill;
  taskBroadcastMessage;
  taskBroadcastMessageOff;
  ttsMessageSkill: Skill;
  taskTtsMessage;

  private currentJob: Mission;
  private currentJobName: string;
  private isConflictJob: boolean = false;
  private robotId: string;
  private subCurrentMissionServices: Subscription;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _datePipe: DatePipe,
    private snackBar: SnackBarService,
    private newJobSrvc: NewJobService,
  ) {
  }

  ngOnInit(): void {

    this.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      //check if the current job is conflict with the current job
      //if the job is conflict open the dialog to replace the current job
      //else submit the form

        this.submitForm();

    });

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.subCurrentMissionServices) {
      this.subCurrentMissionServices.unsubscribe();
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }




  private submitForm(): void {

    // Mapping selected marker into task params object format
    const taskParam = {
      order: 0,
      skillId: this.skillId,
      params: [{ 
        "paramKey": "switch",
        "paramValue": 1
      }],
    };

    //Add all concarent tasks

    let allTask = [];
    allTask.push(taskParam);

    if (this.isBroadcastMessageCheck) {
      // allTask.push(this.taskBroadcastMessage);
      if (this.taskBroadcastMessage[0].params[2].paramKey !== 'tts') {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params url
            this.taskBroadcastMessage[0].params[3], // params type
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      } else {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params tss message
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      }

      // allTask.push(this.taskBroadcastMessageOff);
    }

    // last, add the blinking lights off, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLightsOff);
    }
    // allTask.push(this.taskBroadcastMessage);
    // allTask.push(this.taskTtsMessage);

    // set the value of `orders` in allTask in sequence
    allTask = allTask.map((task, index) => {
      task.order = index + 1;
      return task;
    });

    // Create payload to create goto job based on payload for
    // create mission in https://docs.robotmanager.com/reference/create-a-mission
    const payload = {
      followPath: 1, // 1 = yes; 2 = no;
      mode: 1,
      name:
        'TELECONFERENCE  ' + this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [...allTask],
      layoutId: this.layoutId,
      robotIds: [this.robot.id],
    };


      this.createTeleconferenceJob(payload);
  }

  

  private createTeleconferenceJob(payload) {
    console.log('createTeleconferenceJob=========== ', payload);
    //assign isSubmited = true to show loading in button
    this.newJobSrvc.isSubmited$.next(true);

    this.newJobSrvc.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: `New job added to ${this.robot.name} successfully.`,
          type: 'success',
        });


        this.closeDrawer.emit();
        this.resetType.emit();
      } else {
        this.snackBar.openSnackBar({
          message: res.error ? res.error : `Failed add new job to ${this.robot.name}`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    });
  }
}

