import { AuthApiService } from 'app/services/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  Observable,
  of,
  switchMap,
  throwError,
  takeUntil,
  Subject,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { HmacSHA256 } from 'crypto-js';
import { environment, mqttEnv } from 'environments/environment';
import { LoggerService } from 'my-logger';
import { LoggerApiService, SignInService, MqttSettings } from 'api-services';
import {
  IMqttMessage,
  IMqttServiceOptions,
  MqttService,
  IPublishOptions,
} from 'ngx-mqtt';
import { IClientSubscribeOptions } from 'mqtt-browser';
import { Subscription } from 'rxjs';

export const mqttTopic = {
  robotStatus: '/robot/status/',
  mission: '/robot/mission/',
  missionStatus: '/robot/mission/status/',
  videoConference: '/robot/vc/request/',
  event: '/robot/event/',
  rmRvent: '/rm/event/',
  robotState: '/robot/state/',
  controlStatus: '/robot/control/status/',
  notificationStatus: '/robot/notify/',
  answerCall: '/robot/call/',
  rmMissions: '/rm/mission/', // to get the mission update data from other users
  liftCommandStatus: '/lift/command/status/',
  liftCommand: '/rm/lift/command/',
  liftStatus: '/lift/status/',
  pathUpdate: '/robot/paths/',
  robotLogs: '/robot/log/',
  robotSensor: '/robot/sensor-actuator/status/',
  robotDispatchStatus: '/security/dispatch/status/',
};

export const publishTopics = {
  move: '/rm/move/',
  cameraControl: '/rm/camera/control/',
  autonomous: '/rm/mode/',
  control: '/rm/control/',
  callRobot: '/rm/call/',
  teleconference: '/rm/vc/request/',
};

@Injectable()
export class AuthService implements OnDestroy {
  public companyId: string;
  private _authenticated: boolean = false;
  private COOKIE_NAME = 'rm_security_dashboard';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private curSubscription: Subscription | undefined;

  connection: IMqttServiceOptions;

  receiveNews: string = '';

  client: MqttService | undefined;
  isConnection: boolean = false;
  subscribeSuccess: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _authApi: AuthApiService,
    private loggerService: LoggerService,
    private apiLoggerService: LoggerApiService,
    private _mqttService: MqttService,
    private _mqttSettings: MqttSettings,
    private signinSrv: SignInService
  ) {
    this.client = this._mqttService;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set rmToken(token: string) {
    sessionStorage.setItem('rmToken', token);
  }

  get rmToken(): string {
    return sessionStorage.getItem('rmToken') ?? '';
  }

  set authenticated(authenticated: boolean) {
    this._authenticated = authenticated;
  }

  /**
   * Getter for cookie
   *
   * @returns string
   *
   */
  get cookie(): string {
    return this.getCookieByName(this.COOKIE_NAME);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post('api/auth/sign-in', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this.deleteCookie();
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      console.log('no access token, then check cookie for auth');

      return this._authApi.checkCookie().pipe(
        switchMap((res) => {
          if (res) {
            this._authenticated = true;
            //setup Mqtt connection
            this.setupMqtt();
          }
          return of(res);
        })
      );
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  base64url(source: any): string {
    // Encode in classical base64
    let encodedSource = Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    // Return the base64 encoded string
    return encodedSource;
  }

  generateJWTToken(): string {
    // Define token header
    const header = {
      alg: 'HS256',
      typ: 'JWT',
    };

    // Calculate the issued at and expiration dates
    const date = new Date();
    const iat = Math.floor(date.getTime() / 1000);
    const exp = Math.floor(date.setDate(date.getDate() + 7) / 1000);

    // Define token payload
    const payload = {
      iat: iat,
      iss: 'Fuse',
      exp: exp,
    };

    // Stringify and encode the header
    const stringifiedHeader = Utf8.parse(JSON.stringify(header));
    const encodedHeader = this.base64url(stringifiedHeader);

    // Stringify and encode the payload
    const stringifiedPayload = Utf8.parse(JSON.stringify(payload));
    const encodedPayload = this.base64url(stringifiedPayload);

    // Sign the encoded header and mock-api
    let signature: any = encodedHeader + '.' + encodedPayload;
    signature = HmacSHA256(signature, 'SECURITY_DASHBOARD');
    signature = this.base64url(signature);

    // Build and return the token
    return encodedHeader + '.' + encodedPayload + '.' + signature;
  }

  public deleteCookie(): void {
    if (this.getCookie(this.COOKIE_NAME)) {
      document.cookie = `${
        this.COOKIE_NAME
      }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${this.getCookieHost()}`;
    }
  }

  private getCookieByName = (cookieName: string): string => {
    const found = document.cookie
      .split(';')
      .map((cookie) => {
        const [name, value] = cookie.split('=');
        return { name: name.trim(), value };
      })
      .filter((cookie) => cookie.name === cookieName);

    return found.length ? found[0].value : null;
  };
  private getCookie(name: string): boolean {
    return document.cookie
      .split(';')
      .some((c) => c.trim().startsWith(name + '='));
  }

  private getCookieHost(): string {
    return document.location.host;
  }

  //setup mqtt
  //get the mqttInfo from Signin Api
  //assign this.connection base on the return of the API
  //then create the connection using createConnection();
  private setupMqtt(): void {
    this.signinSrv
      .getMqttInfo()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data['code'] === 200) {
          let mqttInfors = data['result'];
          mqttInfors = JSON.stringify(mqttInfors);
          localStorage.setItem('mqttInfoms', mqttInfors);
          const mqttInfos = JSON.parse(localStorage.getItem('mqttInfoms'));
          const username = mqttInfos['userName'];
          const password = mqttInfos['password'];
          const id = 'rmv2_' + Math.random().toString(16).substr(2, 8);
          let domain = window.location.hostname;
          domain = mqttEnv.mqttHost;
          this.connection = {
            username: username,
            password: password,
            clientId: id,
            protocolVersion: 5,
            protocol: mqttEnv.mqttProtocol,
            port: mqttEnv.mqttPort,
            host: domain,
            hostname: domain,
          };
          this.createConnection();
        }
      });
  }

  // Create a mqtt connection
  private createConnection(): void {
    // Connection string, which allows the protocol to specify the connection method to be used
    // ws Unencrypted WebSocket connection
    // wss Encrypted WebSocket connection
    // mqtt Unencrypted TCP connection
    // mqtts Encrypted TCP connection
    try {
      this.client?.connect(this.connection as IMqttServiceOptions);
    } catch (error) {
      console.error(error);
    }

    //To check if the connection is successful
    this.client?.onConnect.subscribe(() => {
      this.isConnection = true;
      this.subscribeTopic();
    });

    //To check if the connection is failed
    this.client?.onError.subscribe((error: any) => {
      this.isConnection = false;
    });

    //get the connection message
    this.client?.onMessage.subscribe((packet: any) => {
      this.receiveNews = this.receiveNews.concat(packet.payload.toString());
    });
  }

  //Subscribe the mqtt topic
  //Mqtt topic will be always listened as long as the connection is established
  private subscribeTopic(): void {
    //TO DO get the company ID and save to localStorage
    // this.companyId = localStorage.getItem('company_id');
    if (localStorage.getItem('company_id') === null) {
      this.companyId = 'c0000000-c000-c000-c000-c00000000001';
    } else {
      this.companyId = localStorage.getItem('company_id');
    }
    for (const topic in mqttTopic) {
      const subTopic = mqttTopic[topic] + this.companyId;
      this.client?.observe(subTopic).subscribe((message: IMqttMessage) => {
        this.subscribeSuccess = true;
      });
    }

    this.client?.onMessage.subscribe((message: IMqttMessage) => {
      try {
        //save the mqtt message to the data variable
        const data = JSON.parse(message.payload.toString());
        //assign the data variable to the mqtt variable base on the topic
        if (
          message.topic.includes('/mission/status') &&
          !message.topic.includes('robot/mission/status')
        ) {
          this._mqttSettings.socketMissionStatus = data;
        } else if (
          message.topic.includes('/robot/mission') &&
          !message.topic.includes('robot/mission/status')
        ) {
          this._mqttSettings.socketMissionData = data;
        } else if (message.topic.includes('/robot/mission/status')) {
          // TODO: add socketRobotMissionStatus in mqttSettings web-rm-api packages, for now use socketMissionStatus
          this._mqttSettings.socketMissionStatus = data;
        } else if (message.topic.includes('robot/status')) {
          this._mqttSettings.socketRobotData = data;
        } else if (message.topic.includes('robot/event')) {
          this._mqttSettings.socketEventData = data;
        } else if (message.topic.includes('rm/event')) {
          this._mqttSettings.socketRmEventData = data;
        } else if (message.topic.includes('request')) {
          this._mqttSettings.socketVC = data;
        } else if (message.topic.includes('robot/control/status')) {
          this._mqttSettings.socketControlStatus = data;
        } else if (message.topic.includes('robot/notify')) {
          this._mqttSettings.socketNotificationData = data;
        } else if (message.topic.includes('robot/call')) {
          this._mqttSettings.socketAnswerCall = data;
        } else if (message.topic.includes('robot/state')) {
          this._mqttSettings.socketRbtStateData = data;
        } else if (message.topic.includes('/rm/mission/')) {
          this._mqttSettings.socketUserMission = data;
        } else if (message.topic.includes('/rm/mission/status')) {
          this._mqttSettings.socketMissionStatus = data;
        } else if (message.topic.includes('/lift/command/status/')) {
          this._mqttSettings.socketLiftCommandStatus = data;
        } else if (message.topic.includes('/rm/lift/command/')) {
          this._mqttSettings.socketLiftCommand = data;
        } else if (message.topic.includes('/lift/status/')) {
          this._mqttSettings.socketLiftStatus = data;
        } else if (message.topic.includes('/robot/paths')) {
          this._mqttSettings.socketPathPlanner = data;
        } else if (message.topic.includes('/robot/log')) {
          this._mqttSettings.socketRobotLog = data;
        } else if (message.topic.includes('/robot/sensor-actuator/status/')) {
          this._mqttSettings.socketRobotSensors = data;
        } else if (message.topic.includes('/security/dispatch/status/')) {
          this._mqttSettings.socketRmDispatchStatusData = data;
        }
      } catch {
        console.log('Unexpect message: ' + message.payload.toString());
      }
    });
  }
}
