import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileInputService } from './../../../services/file-input.service';
import { SnackBarService } from './../../../services/snack-bar.service';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

interface ObservableFile extends File {
  id?: string;
  detail?: any;
  uploadProgress?: number;
}

@Component({
  selector: 'shared-input-file-dropzone',
  templateUrl: './input-file-dropzone.component.html',
  styleUrls: ['./input-file-dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileDropzoneComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() type: string = 'audio';
  @Input() accept: string;
  @Input() multiple: boolean = false;
  @Input() maxFileSize: number;
  @Input() isShowValidationError: boolean;
  @Input() isShowImageContent: boolean = true;

  @Output() uploadChange = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @Output() handleIsSaveEnabled = new EventEmitter();

  files: ObservableFile[] = [];
  extensions: string = '*';
  fileUploaded: boolean = false;
  constructor(
    private snackBar: SnackBarService,
    private fileInputService: FileInputService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {}

  public getFileExtension(filename: string): string {
    return filename.split('/').pop();
  }

  public getFileType(filename: string): string {
    return filename.split('/').shift();
  }

  public getNotUploadedCount(): number {
    return this.files.filter((file) => !file.id).length;
  }

  public onSelectFile(event: any): void {
    if (this.multiple) {
      this.files.push(...event.addedFiles);
    } else {
      if (event.addedFiles.length > 0) {
        this.files[0] = event.addedFiles[0];
        this.handleUpload();
        this.fileUploaded = true;
      }
    }

    //this is to prevent multiple files from being uploaded
    //only the last file will be uploaded
    if (event.addedFiles.length > 1 && this.multiple === false) {
      this.files.splice(0, 1);
    }
    setTimeout(() => {
      this.checkOverflow();
    })
    // this.checkOverflow();
  }

  public onRemoveFile(event): void {
    this.fileInputService.removeAsset(event.id).subscribe((res) => {
      console.log('removed', res);
    });
    this.files.splice(this.files.indexOf(event), 1);
    this.removeFile.emit();
    if (this.files.length === 0) {
      this.fileUploaded = false;
    }
  }

  public handleUpload() {
    this.handleIsSaveEnabled.emit(false);
    this.files.forEach((file) => {
      if (file.uploadProgress === undefined) {
        file.uploadProgress = 0;
        this.fileInputService
          .uploadFile(file)
          .subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.uploadProgress = Math.round(
                  (event.loaded / event.total) * 100
                );
                break;
              case HttpEventType.Response:
                file.id = event.body.result;
                // Give value to all subscriptions
                this.fileInputService.watchIdValue$.next(file.id);

                this.fileInputService
                  .getAsset(event.body.result)
                  .subscribe((data: any) => {
                    file.detail = data.result;

                    this.uploadChange.emit(this.files);
                    this.handleIsSaveEnabled.emit(true);
                  });
            }
          });
      }
    });
  }
  public reset() {
    this.files = undefined;
  }

  @ViewChild('ellipsisEle') spanElement: ElementRef;
  isTooltipEnabled: boolean = false;
  private checkOverflow() {
    const span = this.spanElement?.nativeElement;
    if (span && (span.offsetWidth < span.scrollWidth)) {
      this.isTooltipEnabled = true;
    } else {
      this.isTooltipEnabled = false;
    }
  }
}
