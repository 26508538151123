import { Skill } from 'rm-api-services/dist/api-services';

export interface AvailableJob {
  name: string;
  skillName: string;
  skill?: Partial<Skill>;
  icon: string;
  jobType: string;
}

export const AVAILABLE_JOBS: AvailableJob[] = [
  {
    name: 'Go to a destination',
    skillName: 'RM-GOTO',
    icon: 'subdirectory_arrow_right',
    jobType: 'goto',
  },
  {
    name: 'Patrol areas',
    skillName: 'RM-GOTO',
    icon: 'pin_drop',
    jobType: 'patrol',
  },
  {
    name: 'Broadcast messages',
    skillName: 'RM-BROADCAST',
    icon: 'chat',
    jobType: 'broadcast',
  },
  {
    name: 'Turn on blinking lights1',
    skillName: 'RM-BLINKER',
    icon: 'wb_twilight',
    jobType: 'blinker',
  },
  {
    name: 'Return to charge',
    skillName: 'RM-GO-CHARGE',
    icon: 'charging_station',
    jobType: 'charge',
  },
  {
    name: 'Teleconference',
    skillName: 'RM-TELECONFERENCE',
    icon: 'video_call',
    jobType: 'calling',
  },
];

export const AVAILABLE_MULTIPLE_ROBOT_JOBS: AvailableJob[] = [
  {
    name: 'Broadcast messages',
    skillName: 'RM-BROADCAST',
    icon: 'chat',
    jobType: 'broadcast',
  },
  {
    name: 'Turn on blinking lights',
    skillName: 'RM-BLINKER',
    icon: 'wb_twilight',
    jobType: 'blinker',
  },
  {
    name: 'Group patrol',
    skillName: 'RM-GOTO',
    icon: 'pin_drop',
    jobType: 'group-patrol',
  },
];
