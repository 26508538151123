import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiBase, ResponseOne } from 'rm-api-services/dist/api-services';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PatrolRouteService extends ApiBase<any> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
  }

  getMediaFiles(pageNo: number = 1, pageSize: number): Observable<any> {
    const psd = {
      pageNo: pageNo,
      pageSize: pageSize,
      filter: [
        {
          name: 'Module',
          column: 'assets.module',
          operator: 'eq',
          value: ['PathMedia'],
          extra: '',
          dataType: 'text',
          virtual: false,
        }
      ],
      order: [
        {
          name: 'Assets Name',
          column: 'assets.updated_at',
          type: 'desc',
        },
      ],
    };
    return this.http.post(`${this.base}/asset/list`, psd);
  } b

  uploadMediaFile(file: File, type: string): Observable<any> {
    return this.http
      .post(`${this.base}/asset/create`, {
        name: file.name,
        type: type,
        description: file.type,
        fileName: file.name,
        module: 'PathMedia'
      })
      .pipe(
        switchMap((response: any) => {
          if(!response.result){
            return of(null);
          }
          const formData = new FormData();
          formData.append('id', response.result);
          formData.append('file', file);
          formData.append('type', type);
          formData.append('fileName', file.name);

          return this.http
            .post(`${this.base}/asset/upload`, formData)
            .pipe(
              map((res: any) => ({
                ...res,
                body: { ...res.body, result: response.result },
              })),
              catchError((err) => {
                return of({
                  code: err.status,
                  body: { result: response.result },
                })
              })
            );
        })
      );
  }

  deleteMediaFile(id: string): Observable<any> {
    return this.http.delete(`${this.base}/asset/${id}`);
  }

  getBroadcastList(pageNo: number = 1, pageSize: number): Observable<any> {
    const psd = {
      pageNo: pageNo,
      pageSize: pageSize,
      lang: 'en',
      content: 'text',
    };
    return this.http.post(`${this.base}/broadcast/list `, psd);
  }

  createBroadcast(text: string): Observable<any> {
    return this.http.post(`${this.base}/broadcast/create`, { lang: "en", content: text });
  }

  deleteBroadcast(id: string): Observable<any> {
    return this.http.delete(`${this.base}/broadcast/${id}`);
  }

}
