import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { PatrolRouteService } from '../route-name-media/patrol-route.service'
import { SnackBarService } from 'app/services/snack-bar.service';

@Component({
  selector: 'route-name-media',
  templateUrl: './route-name-media.component.html',
  styleUrls: ['./route-name-media.component.scss']
})
export class RouteNameMediaComponent implements OnInit {
  @Input() name: string;
  @Output() mediaChanged = new EventEmitter();
  @Output() broadcastChanged = new EventEmitter();

  @ViewChild('mediaBtn') private _mediaBtn: MatButton;
  @ViewChild('broadcastBtn') private _broadcastBtn: MatButton;
  @ViewChild('mediaPanel') private _mediaPanel: TemplateRef<any>;
  @ViewChild('broadcastPanel') private _broadcastPanel: TemplateRef<any>;

  selectedMediaFile = null;
  mediaList: any[] = [];

  broadcastList: any[] = [];
  selectedBroadcast = null;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private snackBar: SnackBarService,
    private _viewContainerRef: ViewContainerRef,
    private patrolRouteSvc: PatrolRouteService,
  ) { }

  ngOnInit(): void {
    this.selectedBroadcast = null;
    this.selectedMediaFile = null;
  }

  clearCheckedMediaFile() {
    this.selectedMediaFile = null;
  }
  clearBroadcastCheck() {
    this.selectedBroadcast = null;
  }

  openPanel(panelType: string): void {
    if (!this._mediaBtn || !this._broadcastBtn || !this._mediaPanel || !this._broadcastPanel) {
      return;
    }
    this.getMediaFiles();
    this.getBroadcasts();

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      if (panelType === 'media') {
        this._createOverlay(this._mediaBtn);
      } else if (panelType === 'broadcast') {
        this._createOverlay(this._broadcastBtn);
      }
    }

    // Attach the portal to the overlay
    if (panelType === 'media') {
      this._overlayRef.attach(
        new TemplatePortal(this._mediaPanel, this._viewContainerRef)
      );
    } else if (panelType === 'broadcast') {
      this._overlayRef.attach(
        new TemplatePortal(this._broadcastPanel, this._viewContainerRef)
      );
    }
  }

  uploadMediaFile(files: File[]) {
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileNameSuffix = fileName.split('.').pop().toLowerCase();
      const fileSize = file.size;
      let fileType = "";
      if (fileNameSuffix === 'jpg' || fileNameSuffix === 'jpeg' || fileNameSuffix === 'png') {
        fileType = "img";
      } else if (fileNameSuffix === 'mp3') {
        fileType = "audio";
      } else if (fileNameSuffix === 'mp4' || fileNameSuffix === 'wav' || fileNameSuffix === 'wma') {
        fileType = "video";
      } else {
        this.snackBar.openSnackBar({
          message: 'The file format is not supported',
          type: 'failed',
        });
        return;
      }
      if(fileSize > 10*1024*1024){
        this.snackBar.openSnackBar({
          message: 'The file size cannot exceed 10M',
          type: 'failed',
        });
        return;
      }
      this.patrolRouteSvc.uploadMediaFile(file, fileType).subscribe({
        next: (res: any) => {
          if (res && res.code === 201 && res.body.result) {
            if (!this.mediaList.find(item => item.id === res.body.result)) {
              this.snackBar.openSnackBar({
                message: 'Upload file success',
                type: 'success',
              });
              this.mediaList.push({
                id: res.body.result,
                type: fileType,
                name: file.name,
                description: file.type,
                fileName: file.name
              });
            }
          } else if (res.code !== 201 && res.body.result) {
            // when create asset success, but upload file failed, remove the asset
            if(res.body.result){
              this.patrolRouteSvc.deleteMediaFile(res.body.result).subscribe((res: any) => {
                console.log('create asset success, but upload file failed');
              });
            }
            this.snackBar.openSnackBar({
              message: 'Upload file failed',
              type: 'failed',
            });
          } else {
            this.snackBar.openSnackBar({
              message: 'Upload file failed',
              type: 'failed',
            });
          }
        },
        error: (error) => {
          this.snackBar.openSnackBar({
            message: 'Upload file failed',
            type: 'failed',
          });
        }
      });
    }
  }

  addBroadcastInput() {
    this.broadcastList.push({
      id: '0',
      isNew: true,
      content: '',
      lang: 'en'
    });
  }

  removeBroadcast(id: string, index: number) {
    if (id) {
      this.patrolRouteSvc.deleteBroadcast(id).subscribe((res: any) => {
        if (res.code === 200) {
          this.snackBar.openSnackBar({
            message: 'Delete broadcast success',
            type: 'success',
          });
          if (this.selectedBroadcast && this.selectedBroadcast.id === id) {
            this.selectedBroadcast = null;
          }
          if (index > -1) {
            this.broadcastList.splice(index, 1);
          }
        } else {
          this.snackBar.openSnackBar({
            message: 'Delete broadcast failed',
            type: 'failed',
          });
        }
      });
    }
  }

  removeMediaFile(id: string, index: number) {
    if (id) {
      this.patrolRouteSvc.deleteMediaFile(id).subscribe((res: any) => {
        if (res.code === 200) {
          this.snackBar.openSnackBar({
            message: 'Delete file success',
            type: 'success',
          });
          if (this.selectedMediaFile && this.selectedMediaFile.id === id) {
            this.selectedMediaFile = null;
          }
          if (index > -1) {
            this.mediaList.splice(index, 1);
          }
        } else {
          this.snackBar.openSnackBar({
            message: 'Delete file failed',
            type: 'failed',
          });
        }
      });
    }
  }

  /**
 * Create the overlay
 */
  private _createOverlay(OriginEle): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      disposeOnNavigation: true,
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(OriginEle)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ])
        .withDefaultOffsetX(-210),
    });
    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
      if (this.selectedBroadcast && this.selectedBroadcast.isNew && this.selectedBroadcast.content) {
        this.patrolRouteSvc.createBroadcast(this.selectedBroadcast.content).subscribe((res: any) => {
          if (res.code === 200) {
            this.snackBar.openSnackBar({
              message: 'Create Broadcast success',
              type: 'success',
            });
            const createOne = {
              id: res.result,
              content: this.selectedBroadcast.content,
              lang: 'en',
            };
            let index = this.broadcastList.findIndex(item => item.id === '0');
            if (index > -1) {
              this.broadcastList[index] = { ...createOne };
            }
            this.selectedBroadcast = { ...createOne };
            this.broadcastChanged.emit(this.selectedBroadcast);
          } else {
            this.snackBar.openSnackBar({
              message: 'Create Broadcast failed',
              type: 'failed',
            });
            this.broadcastChanged.emit(null);
          }
        });
      } else {
        this.broadcastChanged.emit(this.selectedBroadcast);
      }

      this.mediaChanged.emit(this.selectedMediaFile);
    });
  }

  private getMediaFiles(): void {
    this.patrolRouteSvc.getMediaFiles(1, 10).subscribe((res: any) => {
      if (res.code === 200) {
        this.mediaList = res.result.list || [];
        if (this.selectedMediaFile && !this.mediaList.find(item=>item.id === this.selectedMediaFile.id)) {
          this.selectedMediaFile = null;
        }
      }
    });
  }

  private getBroadcasts(): void {
    this.patrolRouteSvc.getBroadcastList(1, 10).subscribe((res: any) => {
      if (res.code === 200) {
        this.broadcastList = res.result.list || [];
        if (this.selectedBroadcast && !this.broadcastList.find(item=>item.id === this.selectedBroadcast.id)) {
          this.selectedBroadcast = null;
        }
      }
    });
  }

}
