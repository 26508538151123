import { ElementRef, Injectable } from '@angular/core';
import { MapRobotMarker } from 'app/services/api.types';
import { getAngle, getClasses, getMissionStatusString, moveToPosition } from 'app/shared/utils/robot-marker-util';
import { get } from 'lodash';
import {
  AnimationOptions,
  FlyToOptions,
  LngLatLike,
  Map,
  Marker,
  Popup,
} from 'maplibre-gl';
import { Layout, Robot, Location } from 'rm-api-services/dist/api-services';
import { DashboardService } from '../../dashboard.service';

interface MonitoringLayout extends Layout {
  detectionNumber?: number;
  malfunctionNumber?: number;
  eventCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class MapLibreService {
  private map: Map;
  private mapZoom: number;
  // use to check if the zoom event triger using the zoom tool (button / slider) or mouse scroll or pitch gesture
  private isUseZoomTool: boolean = false;
  private markerList: { [markerId: string]: Marker } = {};
  private updateRobotPositionFrame;

  constructor(private dashboardService: DashboardService) {}

  /**
   * Helper function to init map using Maplibre library.
   * After map initiated, it will set function when
   * map load, zooming, & finish zooming
   *
   * @param mapRef Map reference for maplibre
   * @param center Center of the map
   * @param callback Callback after map loaded
   */
  public initMap(
    mapRef: ElementRef,
    center: LngLatLike,
    callback: () => void
  ): void {
    const zoom = localStorage.getItem('zoom');
    this.mapZoom = (zoom && Number(zoom)) || 15;
    this.dashboardService.mapZoom$.next(this.mapZoom);
    this.map = new Map({
      container: mapRef.nativeElement,
      style:
        'https://api.maptiler.com/maps/streets/style.json?key=CddPes2siR2dLXJRRVw0',
      zoom: this.mapZoom,
      center,
      antialias: true,
      pitch: 0,
      bearing: 0,
      attributionControl: false,
    });

    this.map.on('load', () => {
      callback();
      this.map.resize();
    });

    // set current zoom level for slider when user zoom the map
    // using mouse scroll or pinch gesture, so it will reflect
    // the zoom tool slider thumb position.
    this.map.on('zoom', () => {
      if (!this.isUseZoomTool) {
        this.mapZoom = this.map.getZoom();
        this.dashboardService.mapZoom$.next(this.mapZoom);
      }
    });

    // set the variable isUseZoomTool to false after zoom finish,
    // so when user use mouse scroll or pitch gesture it will
    // reflect the zoom tool slider thumb position.
    // Then save the current zoom level to local storage.
    this.map.on('zoomend', () => {
      if (this.isUseZoomTool) {
        this.isUseZoomTool = false;
      }
      const zoom = this.map.getZoom();
      localStorage.setItem('zoom', `${zoom}`);
    });
  }

  /**
   * Helper function used to set checking varibale if the zoom event triger
   * using the zoom tool (button / slider) or mouse scroll or pitch gesture
   *
   * @param value true / false
   */
  public setIsUseZoomTool(value: boolean): void {
    this.isUseZoomTool = value;
  }

  /**
   * Helper function for create layout marker / robot marker
   * in the Maplibre map depend on the marker type (marker / robot)
   *
   * @param dataList list of data marker to be rendered
   * @param type marker type can be 'marker' or 'robot'
   * @param isShow Show or hide markers in the map
   * @param callback Callback when the marker is clicked
   */
  public createMarkers(
    dataList: any[],
    type: 'marker' | 'robot' | 'event',
    isShow: boolean,
    callback?: (marker: MonitoringLayout | MapRobotMarker) => void
  ): void {
    dataList
      .filter((data) => {
        return (
          (data.location && this.isValidLocation(data.location)) ||
          (data.point && this.isValidLocation(data.point))
        );
      })
      .forEach((data) => {
        this.createMarker(data, type, isShow, callback);
      });
  }

  public createMarker(
    data: any,
    type: 'marker' | 'robot' | 'event',
    isShow: boolean,
    callback?: (marker: MonitoringLayout | MapRobotMarker) => void
  ): void {
    const markerHtml = this.createMarkerHtml(data, type, isShow, callback);
    const location =
      data.location && this.isValidLocation(data.location)
        ? data.location
        : data.point
        ? data.point
        : undefined;
    const marker = new Marker({
      anchor: 'bottom',
      element: markerHtml,
      offset: [0, 0],
    });
    if (location) {
      marker.setLngLat([location.lng, location.lat]).addTo(this.map);
    }

    // create index for created marker
    this.markerList[data.id] = marker;
  }

  /**
   * Helper function to zoom in the map
   */
  public zoomIn(): void {
    this.map.zoomIn();
  }

  /**
   * Helper function to zoom in the map
   */
  public zoomOut(): void {
    this.map.zoomOut();
  }

  /**
   * Helper function to zoom map by specific zoom level
   *
   * @param zoom specific zoom level
   * @param options Animation option when scrolling (optional)
   * @param eventData Additional properties to be added to event objects of events triggered by this method (optional)
   */
  public zoomTo(
    zoom: number,
    options?: AnimationOptions,
    eventData?: any
  ): void {
    this.map.zoomTo(zoom, { ...options }, { ...eventData });
  }

  /**
   * Helper function to set center point of the map
   *
   * @param options Options describing the destination and animation of the transition (optional)
   * @param eventData Additional properties to be added to event objects of events triggered by this method. (optional)
   */
  public flyTo(options: FlyToOptions, eventData?: any): void {
    this.map.flyTo({ ...options }, { ...eventData });
  }

  /**
   * Helper function to show pop up dialog for selected layout marker
   * in the Maplibre map
   *
   * @param robots list of robots in selected layout marker
   * @param layout selected layout marker
   * @param coordinate coordinate to show the pop up
   * @param callback Callback when the Enter button is clicked
   */
  public showPopup(
    robots: MapRobotMarker[],
    layout: MonitoringLayout,
    coordinate: [number, number],
    callback: () => void
  ): void {
    const sortedRobots = robots.sort((a, b) => {
      if(a.status > b.status){
        return 1;
      } else if (a.status < b.status){
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
    const htmlDom = this.createPopupHtml(sortedRobots, layout, callback);
    new Popup({
      closeButton: false,
      offset: [-30, -30],
      anchor: 'right',
      className: 'layout-detail-popup',
    })
      .setLngLat(coordinate)
      .setDOMContent(htmlDom)
      .setMaxWidth('500px')
      .addTo(this.map);
  }

  /**
   * Helper function for update the marker DOM for change the color
   * if there is a new detection occur for that marker (layout / marker)
   * and re render it in the map
   *
   * @param markerData Data which marker to be updated
   * @param type marker type can be 'marker' or 'robot'
   * @returns HTML element
   */
  public updateMarkerHtml(
    markerData: MonitoringLayout | MapRobotMarker,
    type: 'robot' | 'marker'
  ): void {
    const parent = document.getElementById(markerData.id);

    if (parent) {
      const strDetection =
        markerData.detectionNumber > 1 ? 'detections' : 'detection';

      const titleQueryDomClass =
        type === 'robot' ? '.marker-title.robot' : '.marker-title.layout';
      const markerQueryDomClass =
        type === 'robot' ? '.marker.robot-marker' : '.marker.layout';

      // assign the dom HTML to variable and set the class list
      const titleDom = parent.querySelector(titleQueryDomClass);
      const markerDom = parent.querySelector(markerQueryDomClass);
      const iconDom = parent.querySelector('.icon-container');
      const blinkerDetection = parent.querySelector('.detection-blinking');
      titleDom.classList.replace('normal', 'detection');
      markerDom.classList.replace('normal', 'detection');
      blinkerDetection.classList.remove('hidden');

      // if type marker is robot, it will update the color of the heading robot sonar
      if (type === 'robot') {
        const sonarDom = parent.querySelector('.robot-sonar');
        sonarDom.classList.replace('normal', 'detection');
        const robotIconDom = parent.querySelector('.robot-icon');
        robotIconDom.classList.replace('normal', 'detection');
        const robotBgLogoDom = parent.querySelector('.bg-robot-logo');
        robotBgLogoDom.classList.replace('normal', 'detection');
      }

      // if the layout or robot marker not in detection mode, it will add the detection icon to dom marker
      if (markerData.detectionNumber === 1) {
        const detectionLogo = document.createElement('img');
        detectionLogo.classList.add('w-4', 'detection-icon');
        detectionLogo.setAttribute(
          'src',
          '/assets/images/markers/detection-logo.svg'
        );
        iconDom.appendChild(detectionLogo);
        iconDom.classList.remove('hidden');
      }

      // if the layout or robot marker is already in detection mode, it will update the detection number
      if (markerData.detectionNumber > 1) {
        const removedQuerySelector =
          type === 'robot'
            ? '.detection-number.robot'
            : '.detection-number.marker';
        titleDom.removeChild(parent.querySelector(removedQuerySelector));
      }

      const detectionText = document.createElement('div');
      detectionText.classList.add('detection-number', type);
      detectionText.innerText = `${markerData.detectionNumber} ${strDetection}`;
      titleDom.appendChild(detectionText);
    }
  }

  /**
   * Helper function for update the marker DOM for change the color
   * if there is a new malfuntion robot for that marker (layout / marker)
   * and re render it in the map
   *
   * @param markerData Data which marker to be updated
   * @param type marker type can be 'marker' or 'robot'
   * @returns HTML element
   */
  public updateMalfunctionMarkerHtml(
    markerData: MonitoringLayout | MapRobotMarker,
    type: 'robot' | 'marker'
  ): void {
    const parent = document.getElementById(markerData.id);

    if (parent) {
      const titleQueryDomClass =
        type === 'robot' ? '.marker-title.robot' : '.marker-title.layout';
      const markerQueryDomClass =
        type === 'robot' ? '.marker.robot-marker' : '.marker.layout';

      // assign the dom HTML to variable and set the class list
      const titleDom = parent.querySelector(titleQueryDomClass);
      const markerDom = parent.querySelector(markerQueryDomClass);
      const iconDom = parent.querySelector('.icon-container');
      titleDom.classList.replace('normal', 'detection');
      markerDom.classList.replace('normal', 'detection');

      if (markerData.malfunctionNumber > 0) {
        const malfunctionLogo = document.createElement('img');
        malfunctionLogo.classList.add('w-4', 'malfunction-icon');
        malfunctionLogo.setAttribute(
          'src',
          '/assets/images/markers/malfunction-robot-logo.svg'
        );

        if (!iconDom.hasChildNodes()) {
          iconDom.classList.remove('hidden');
        }

        const oldMalLogo = iconDom.querySelector('.malfunction-icon');
        if (!oldMalLogo) {
          iconDom.appendChild(malfunctionLogo);
        }

        // if type marker is robot, it will update the color of the heading robot sonar
        if (type === 'robot') {
          const sonarDom = parent.querySelector('.robot-sonar');
          sonarDom.classList.replace('normal', 'detection');
          const robotIconDom = parent.querySelector('.robot-icon');
          robotIconDom.classList.replace('normal', 'detection');
          const robotBgLogoDom = parent.querySelector('.bg-robot-logo');
          robotBgLogoDom.classList.replace('normal', 'detection');
        }
      } else {
        const oldMalLogo = iconDom.querySelector('.malfunction-icon');
        if (oldMalLogo) {
          iconDom.removeChild(oldMalLogo);
        }

        if (!iconDom.hasChildNodes()) {
          iconDom.classList.add('hidden');
        }

        if (markerData.detectionNumber === 0) {
          titleDom.classList.replace('detection', 'normal');
          markerDom.classList.replace('detection', 'normal');

          // if type marker is robot, it will update the color of the heading robot sonar
          if (type === 'robot') {
            const sonarDom = parent.querySelector('.robot-sonar');
            sonarDom.classList.replace('detection', 'normal');
            const robotIconDom = parent.querySelector('.robot-icon');
            robotIconDom.classList.replace('detection', 'normal');
            const robotBgLogoDom = parent.querySelector('.bg-robot-logo');
            robotBgLogoDom.classList.replace('detection', 'normal');
          }
        }
      }
    }
  }

  /**
   * Helper function to update the marker DOM of pop up element
   * for change the robot status
   * and re render it in the map
   *
   * @param robot Robot data for selected layout amrker
   */
  public updatePopupHtml(
    robot: Robot,
    statusText?: string,
    statusColor?: string,
    missionName?: string,
    missionStatus?: string
  ): void {
    const parent = document.getElementById(robot.id + '_popup');
    if (parent) {
      // assign the dom HTML to variable and set the class list
      const statusDom = parent.querySelector('.robot-status');
      const statusTextDom = parent.querySelector('.robot-status-text');
      if (robot.status === 1) {
        statusDom.classList.remove('offline', 'online', 'idle');
        statusDom.classList.add(statusColor);
        statusTextDom.textContent = statusText;
      } else if (robot.status === 2) {
        statusDom.classList.remove('online', 'offline', 'idle');
        statusDom.classList.add('offline');
        statusTextDom.textContent = 'Offline';
      }

      if (robot.state === '5' || robot.state === 5) {
        statusDom.classList.remove('offline', 'online', 'idle', 'error');
        statusDom.classList.add(statusColor);

        const malfunctionLogo = document.createElement('img');
        malfunctionLogo.classList.add('malfunction-icon');
        malfunctionLogo.setAttribute(
          'src',
          '/assets/images/markers/malfunction-logo.svg'
        );

        if (!statusDom.hasChildNodes()) {
          statusDom.appendChild(malfunctionLogo);
        }
        statusTextDom.textContent = statusText;
        statusTextDom.classList.add('text-error-300');
      } else {
        const oldMalLogo = statusDom.querySelector('.malfunction-icon');
        if (oldMalLogo) {
          statusDom.removeChild(oldMalLogo);
        }
        statusDom.classList.remove('error');
        statusTextDom.classList.remove('text-error-300');
      }

      // handle battery
      if(robot.status === 1){
        const batteryDom = parent.querySelector('.battery-icon');
        if(Number(robot.battery) > 50){
          batteryDom.setAttribute(
            'src',
            '/assets/icons/battery-full.svg'
          );
        }else if(Number(robot.battery) <= 50 && Number(robot.battery) > 20){
          batteryDom.setAttribute(
            'src',
            '/assets/icons/battery-half.svg'
          );
        }else if(Number(robot.battery) <= 20){
          batteryDom.setAttribute(
            'src',
            '/assets/icons/battery-low.svg'
          );
        }
      }

      // handle connectivity
      if(robot.status !== 2){
        const connectivityDom = parent.querySelector('.connectivity-icon');
        if(Number(robot.connectivity) > 50){
          connectivityDom.setAttribute(
            'src',
            '/assets/icons/connect-full.svg'
          );
        }else if(Number(robot.connectivity) <= 50 && Number(robot.battery) > 20){
          connectivityDom.setAttribute(
            'src',
            '/assets/icons/connect-mid.svg'
          );
        }else if(Number(robot.connectivity) <= 20){
          connectivityDom.setAttribute(
            'src',
            '/assets/icons/connect-low.svg'
          );
        }
      }

      // handle mission
      if(missionName){
        const missionBoxDom = parent.querySelector('.mission-status-box');
        if(missionBoxDom){
          const missionNameDom = missionBoxDom.querySelector('.mission-name');
          if(missionNameDom){
            missionNameDom.textContent = missionName;
          }
          const missionStatusDom = missionBoxDom.querySelector('.mission-status');
          if(missionStatusDom){
            missionStatusDom.classList.remove('bg-success', 'bg-black', 'bg-orange-600', 'bg-amber-500', 'bg-cyan-500', 'bg-red-700');
            let statusStr = getMissionStatusString(Number(missionStatus));
            missionStatusDom.classList.add(getClasses(statusStr));
            missionStatusDom.textContent = statusStr;
          }
        }else{
          parent.appendChild(this.createPopupMissionHtml(missionName, missionStatus));
        }
      }
    }
  }

  /**
   * Helper function to check if map component is already initiate
   *
   * @returns boolean
   */
  public isMapInitiate(): boolean {
    return this.map ? true : false;
  }

  /**
   * Helper function to update the position of the robot
   * marker on the map, animate the movement to
   * destination position and rotate the angle heading
   *
   * @param robotData Updated robot data
   */
  public updateRobotMarkerPosition(robotData: MapRobotMarker): void {
    const robot = this.markerList[robotData.id];
    const fromPosition = robot.getLngLat();
    const toPosition = robotData.location;
    // while the marker is moving, but the position is updated.
    // It will stop the current movement then update to
    // the new position and angle
    if (this.updateRobotPositionFrame) {
      this.updateRobotPositionFrame.stop();
    }
    this.updateRobotPositionFrame = moveToPosition(
      [fromPosition.lng, fromPosition.lat],
      [toPosition.lng, toPosition.lat],
      (position: [number, number]) => {
        const xLat = position[1];
        const yLng = position[0];
        if (!isNaN(xLat) && !isNaN(yLng)) {
          // set the marker position per movement and calculate the heading angle
          robot.setLngLat([yLng, xLat]);
          const angle = getAngle(
            [fromPosition.lng, fromPosition.lat],
            [toPosition.lng, toPosition.lat]
          );

          // set the marker dom heading angle, based on result calculation angle
          const parent = document.getElementById(robotData.id);
          if (parent) {
            const sonarDom = parent.querySelector('.robot-sonar');
            sonarDom.setAttribute(
              'style',
              'transform-origin: bottom center 0px;transform: rotate(' +
                angle +
                'deg)'
            );
          }
        }
      },
      60,
      0.000009
    );
  }

  /**
   * Helper function to set the map view type, such as 2D or 3D.
   * It will use fill-extrusion property from MapLibre library
   * to set the view on the map
   *
   * @param type Map view type. Can be '2d' or '3d'
   */
  public setMapView(type: string): void {
    // set to 2D view
    if (type === '2d') {
      const pitch = this.map.getPitch();
      if (pitch !== 0) {
        this.map.easeTo({ pitch: 0, bearing: 0 });
      }
      this.map.dragRotate.disable();
      this.map.setPaintProperty('building-3d', 'fill-extrusion-height', 0);
      this.map.setPaintProperty('building-3d', 'fill-extrusion-base', 0);
    } else if (type === '3d') {
      // Set to 3D view
      this.map.easeTo({ pitch: 40 });
      this.map.dragRotate.enable();
      this.map.setPaintProperty('building-3d', 'fill-extrusion-height', {
        type: 'identity',
        property: 'render_height',
      });
      this.map.setPaintProperty('building-3d', 'fill-extrusion-base', {
        type: 'identity',
        property: 'render_min_height',
      });
    }
  }

  /**
   * Helper function to update robot number display in the layout marker
   *
   * @param layoutId Layout ID get from API
   * @param robotNumber Total robot on specific layout that show on the map
   */
  public updateRobotNumberOnLayout(
    layoutId: string,
    robotNumber: number
  ): void {
    const parent = document.getElementById(layoutId);

    if (parent) {
      // assign the dom HTML to variable and set the class list
      // and update the robot number on layout
      const robotNumberDom = parent.querySelector('.robot-number');
      robotNumberDom.innerHTML = robotNumber.toString();
    }
  }

  public removeMarker(refId: string): void {
    // create index for created marker
    const marker = get(this.markerList, refId, '');
    if (marker) {
      delete this.markerList[refId];
      const domElement = document.getElementById(refId);
      if (domElement) {
        domElement.remove();
      }
    }
  }

  /**
   * Helper function to update robot number display in the layout marker
   *
   * @param layoutId Layout ID get from API
   * @param robotNumber Total robot on specific layout that show on the map
   */
  public updateRobotMalfunctionOnLayout(layout: MonitoringLayout): void {
    const parent = document.getElementById(layout.id);

    if (parent) {
      const titleDom = parent.querySelector('.marker-title.layout');
      const markerDom = parent.querySelector('.marker.layout');
      if (layout.malfunctionNumber > 0) {
        titleDom.classList.replace('normal', 'detection');
        markerDom.classList.replace('normal', 'detection');
        // assign the dom HTML to variable and set the class list
        // and update the robot number on layout
        const strMalfuntion =
          layout.malfunctionNumber > 1 ? 'malfunctions' : 'malfunction';
        const robotNumberDom = parent.querySelector('.malfunction-number');
        if (robotNumberDom) {
          robotNumberDom.innerHTML = layout.malfunctionNumber.toString();
          robotNumberDom.innerHTML = `${layout.malfunctionNumber} ${strMalfuntion}`;
        } else {
          const malfunctionText = document.createElement('div');
          malfunctionText.classList.add('malfunction-number', 'marker');
          malfunctionText.innerText = `${layout.malfunctionNumber} ${strMalfuntion}`;

          const titleDoc = parent.querySelector('.marker-title');
          titleDoc.appendChild(malfunctionText);

          const malfunctionLogo = document.createElement('img');
          malfunctionLogo.classList.add('w-4', 'malfunction-icon');
          malfunctionLogo.setAttribute(
            'src',
            '/assets/images/markers/malfunction-robot-logo.svg'
          );

          const logoCont = parent.querySelector('.icon-container');
          logoCont.appendChild(malfunctionLogo);
          logoCont.classList.remove('hidden');
        }
      } else {
        const logoCont = parent.querySelector('.icon-container');
        const malRobotIcon = parent.querySelector('.malfunction-icon');
        if (malRobotIcon) {
          logoCont.removeChild(malRobotIcon);
        }

        const markerTitleDom = parent.querySelector('.marker-title');
        const malNumberDom = parent.querySelector('.malfunction-number');
        if (malNumberDom) {
          markerTitleDom.removeChild(malNumberDom);
        }

        if (layout.detectionNumber === 0) {
          logoCont.classList.add('hidden');
          titleDom.classList.replace('detection', 'normal');
          markerDom.classList.replace('detection', 'normal');
        }
      }
    }
  }

  public resizeMap(): void {
    if (this.map) {
      this.map.resize();
    }
  }

  /**
   * Helper funtion to check the location is valid or not
   *
   * @param location location object (latitude & longitude)
   * @returns boolean
   */
  private isValidLocation(location: Location): boolean {
    if (location && location.lat && location.lng) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Helper function for create HTML format to render
   * marker that used custom icon in the Maplibre map
   * depend on the marker type (marker / robot)
   *
   * @param markerData Data which marker to be rendered
   * @param type marker type can be 'marker' or 'robot'
   * @param isShow Show or hide markers in the map
   * @param callback Callback when the marker is clicked
   * @returns HTML element
   */
  private createMarkerHtml(
    markerData: any,
    type: 'marker' | 'robot' | 'event',
    isShow: boolean,
    callback?: (marker: MonitoringLayout | MapRobotMarker) => void
  ): HTMLDivElement {
    const visibility = isShow ? 'visible' : 'hidden';
    // assign the dom HTML to variable and set the class list
    const detectionClass =
      markerData.detectionNumber > 0 || markerData.malfunctionNumber > 0
        ? 'detection'
        : 'normal';
    const parent = document.createElement('div');
    parent.classList.add('dashboard-marker');
    parent.setAttribute('id', markerData.id);
    parent.style.visibility = visibility;

    const el = document.createElement('div');
    if (callback) {
      el.addEventListener('click', () => {
        callback(markerData);
      });

      el.addEventListener('mouseenter', (e) => {
        const targetPopup = document.getElementById(markerData.id + '_popup_box');
        const allMarkerPopups = document.getElementsByClassName('marker-popup-box');
        if(!targetPopup){
          this.map.getCanvas().click();
          callback(markerData);
        }
      });

      // el.addEventListener('mouseleave', (e) => {
      //   this.map.getCanvas().click();
      // });
    }

    const markerDoc = document.createElement('div');
    markerDoc.classList.add('marker');

    const titleDoc = document.createElement('div');
    titleDoc.classList.add('marker-title');

    const title = document.createElement('div');
    const logoCont = document.createElement('span');
    logoCont.classList.add(
      'icon-container',
      '-mt-[25px]',
      '-ml-[42px]',
      'absolute',
      'flex',
      'items-center',
      'justify-center',
      'w-10',
      'gap-2',
      'hidden'
    );
    titleDoc.appendChild(logoCont);

    const blinkerDetection = document.createElement('div');
    blinkerDetection.classList.add('detection-blinking', 'hidden');
    el.appendChild(blinkerDetection);

    if (markerData.detectionNumber > 0 && type !== 'event') {
      blinkerDetection.classList.remove('hidden');
    }

    // check the marker type
    // if it is robot, use the robot class to set the marker icon and color
    // if it is marker, use the layout class to set the marker icon and color
    switch (type) {
      case 'robot':
        markerDoc.classList.add('robot-marker', detectionClass);

        const sonarRobot = document.createElement('div');
        sonarRobot.classList.add('robot-sonar', detectionClass);
        sonarRobot.style.transformOrigin = 'bottom center';
        sonarRobot.style.transform = 'rotate(' + 0 + 'deg)';

        const bgRobot = document.createElement('div');
        bgRobot.classList.add('bg-robot-logo', detectionClass);

        titleDoc.classList.add('robot', detectionClass);
        title.innerText = markerData.name;

        el.appendChild(sonarRobot);
        el.appendChild(bgRobot);
        blinkerDetection.classList.add('robot');
        break;
      case 'marker':
        el.classList.add('shadow-marker');
        markerDoc.classList.add('layout', detectionClass);
        titleDoc.classList.add('layout', detectionClass);
        title.innerText = markerData.name;

        const number = document.createElement('div');
        number.classList.add('robot-number');
        const loadingSvg = document.createElement('img');
        loadingSvg.classList.add('w-5', 'mx-auto');
        loadingSvg.setAttribute(
          'src',
          '/assets/images/markers/loading-marker.svg'
        );
        number.appendChild(loadingSvg);
        markerDoc.appendChild(number);
        blinkerDetection.classList.add('layout');
        break;
      case 'event':
        markerDoc.classList.add('event-marker', 'detection');
        titleDoc.classList.add('event-marker', 'detection');
        title.innerText = markerData.title;
        const bgEvent = document.createElement('div');
        bgEvent.classList.add('bg-event-logo');
        el.appendChild(bgEvent);
        break;
      default:
        markerDoc.classList.add('layout', 'normal');
        break;
    }

    titleDoc.appendChild(title);

    // if the layout or robot marker is already in detection mode,
    // it will show the detection number and detection icon.
    // Also set the marker color to red
    if (markerData.detectionNumber > 0 && type !== 'event') {
      const strDetection =
        markerData.detectionNumber > 1 ? 'detections' : 'detection';
      const detectionText = document.createElement('div');
      detectionText.classList.add('detection-number', type);
      detectionText.innerText = `${markerData.detectionNumber} ${strDetection}`;
      titleDoc.appendChild(detectionText);

      const detectionLogo = document.createElement('img');
      detectionLogo.classList.add('w-4', 'detection-icon');
      detectionLogo.setAttribute(
        'src',
        '/assets/images/markers/detection-logo.svg'
      );
      logoCont.appendChild(detectionLogo);
      logoCont.classList.remove('hidden');
    }

    // if the layout or robot marker is already in detection mode,
    // it will show the detection number and detection icon.
    // Also set the marker color to red
    if (markerData.malfunctionNumber > 0 && type !== 'event') {
      const strMalfuntion =
        markerData.malfunctionNumber > 1 ? 'malfunctions' : 'malfunction';
      const malfunctionText = document.createElement('div');
      malfunctionText.classList.add('malfunction-number', type);
      malfunctionText.innerText = `${markerData.malfunctionNumber} ${strMalfuntion}`;

      if (type === 'marker') {
        titleDoc.appendChild(malfunctionText);
      }

      const malfunctionLogo = document.createElement('img');
      malfunctionLogo.classList.add('w-4', 'malfunction-icon');
      malfunctionLogo.setAttribute(
        'src',
        '/assets/images/markers/malfunction-robot-logo.svg'
      );
      logoCont.appendChild(malfunctionLogo);
      logoCont.classList.remove('hidden');
    }

    el.appendChild(markerDoc);
    if (type === 'robot') {
      const robotIcon = document.createElement('div');
      robotIcon.classList.add('robot-icon', detectionClass, 'cursor-pointer');
      el.appendChild(robotIcon);
    }
    parent.appendChild(titleDoc);
    parent.appendChild(el);
    return parent;
  }

  /**
   * Helper function for create HTML format to render
   * pop up layout detail in the Maplibre map
   *
   * @param robots list of robots in selected layout marker
   * @param layout selected layout marker
   * @param callback Callback when the button is clicked
   * @returns HTML element
   */
  private createPopupHtml(
    robots: MapRobotMarker[],
    layout: MonitoringLayout,
    callback: () => void
  ): HTMLDivElement {
    // assign the dom HTML to variable and set the class list
    const strRobot = robots.length > 1 ? 'robots' : 'robot';
    const strDetection =
      layout.detectionNumber > 1 ? 'detections' : 'detection';
    const roundedPopUp = robots.length > 0 ? 'rounded-t-lg' : 'rounded-lg';
    const parent = document.createElement('div');
    parent.classList.add('flex', 'flex-col', 'w-full', 'text-white', 'marker-popup-box');
    parent.setAttribute('id', layout.id + '_popup_box');

    const topDiv = document.createElement('div');
    topDiv.classList.add(
      'flex',
      'p-4',
      'flex-row',
      'items-start',
      'justify-start',
      'bg-neutral-900',
      roundedPopUp
    );

    const topLeftDiv = document.createElement('div');
    topLeftDiv.classList.add('w-2/3', 'text-left');

    const layoutName = document.createElement('p');
    layoutName.classList.add('text-white', 'font-semibold', 'text-lg', 'truncate');
    layoutName.innerText = layout.name;

    const robotNumber = document.createElement('p');
    robotNumber.classList.add('text-neutral-100', 'font-normal', 'text-sm');
    robotNumber.innerText = `${robots.length} ${strRobot} in this building`;

    const detectionNumber = document.createElement('p');
    detectionNumber.classList.add('text-neutral-100', 'font-normal', 'text-sm');
    detectionNumber.innerText = `${layout.detectionNumber} ${strDetection}`;

    topLeftDiv.appendChild(layoutName);
    topLeftDiv.appendChild(robotNumber);
    topLeftDiv.appendChild(detectionNumber);
    topDiv.appendChild(topLeftDiv);

    const topRightDiv = document.createElement('div');
    topRightDiv.classList.add('w-1/3');

    const buttonNext = document.createElement('button');
    buttonNext.classList.add(
      'w-full',
      'rounded-md',
      'max-h-9',
      'h-9',
      'bg-primary',
      'hover:bg-primary-700',
      'flex',
      'items-center',
      'justify-center',
      'text-white',
      'font-medium',
      'text-base'
    );
    buttonNext.style.boxShadow =
      '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)';
    buttonNext.innerText = 'Enter';
    buttonNext.addEventListener('click', () => {
      callback();
    });

    const matIcon = document.createElement('img');
    matIcon.classList.add('w-4', 'ml-2');
    matIcon.setAttribute('src', '/assets/images/markers/forward-arrow.svg');

    buttonNext.appendChild(matIcon);
    topRightDiv.appendChild(buttonNext);
    topDiv.appendChild(topRightDiv);
    parent.appendChild(topDiv);

    const bottomDiv = document.createElement('div');
    bottomDiv.classList.add(
      'px-4',
      'w-full',
      'flex',
      'flex-col',
      'items-start',
      'justify-start',
      'bg-neutral-700',
      'max-h-64',
      'overflow-y-auto',
      'rounded-b-lg'
    );

    // show the robot list in the popup detail
    robots.forEach((robot) => {
      const strRobotStatus = robot.status === 2 ? 'Offline' : 'Idle';
      const robotCard = document.createElement('div');
      robotCard.classList.add('w-full', 'gap-4', 'my-2');
      robotCard.setAttribute('id', robot.id + '_popup');

      const robotDetails = document.createElement('div');
      robotDetails.classList.add('w-full', 'flex', 'flex');
      // robot name
      const robotName = document.createElement('p');
      robotName.classList.add(
        'text-left',
        'w-2/3',
        'text-white',
        'font-semibold',
        'text-base',
        'truncate'
      );
      robotName.innerText = robot.name;

      //robot status
      const robotInfo = document.createElement('div');
      robotInfo.classList.add(
        'w-1/3',
        'text-right',
        'flex',
        'justify-between',
        'items-center'
      );

      const robotStatusText = document.createElement('span');
      robotStatusText.classList.add(
        'w-full',
        'text-neutral-100',
        'font-normal',
        'text-xs',
        'robot-status-text'
      );

      if (robot.malfunctionNumber > 0) {
        const malfunctionLogo = document.createElement('img');
        malfunctionLogo.classList.add('malfunction-icon','icon-size-4','error');
        malfunctionLogo.setAttribute(
          'src',
          '/assets/images/markers/malfunction-logo.svg'
        );
        robotStatusText.classList.add('text-error-300');
        robotStatusText.innerText = 'Malfunctioning';
        robotInfo.appendChild(malfunctionLogo);
        robotInfo.appendChild(robotStatusText);
      } else {
        const robotStatus = document.createElement('div');
        const robotStatusLogo = document.createElement('span');
        robotStatusLogo.classList.add('robot-status', 'mr-1');
        if (robot.status === 1) {
          robotStatusLogo.classList.add('idle');
        } else if (robot.status === 2) {
          robotStatusLogo.classList.add('offline');
        }
        robotStatusText.innerText = `${strRobotStatus}`;

        robotStatus.appendChild(robotStatusLogo);
        robotStatus.appendChild(robotStatusText);
        robotInfo.appendChild(robotStatus);
        const batteryIcon = document.createElement('img');
        batteryIcon.classList.add('battery-icon','icon-size-4');
        batteryIcon.setAttribute(
          'src',
          '/assets/icons/battery.svg'
        );
        robotInfo.appendChild(batteryIcon);
        const wifiIcon = document.createElement('img');
        wifiIcon.classList.add('connectivity-icon','icon-size-4');
        wifiIcon.setAttribute(
          'src',
          '/assets/icons/network.svg'
        );
        robotInfo.appendChild(wifiIcon);
      }

      robotDetails.appendChild(robotName);
      robotDetails.appendChild(robotInfo);

      const robotLocation = document.createElement('div');
      robotLocation.classList.add(
        'flex',
        'flex-row',
        'items-center',
        'pt-1',
        'pb-1'
      );
      const locationIcon = document.createElement('img');
      locationIcon.classList.add('map-icon','icon-size-4');
      locationIcon.setAttribute(
        'src',
        '/assets/icons/map.svg'
      );

      const locationText = document.createElement('span');
      locationText.classList.add(
        'text-neutral-100',
        'font-normal',
        'text-s',
        'pl-1',
        'w-11/12',
        'truncate'
      );
      locationText.innerText = robot.pointName;

      robotLocation.appendChild(locationIcon);
      robotLocation.appendChild(locationText);
      robotCard.appendChild(robotDetails);
      robotCard.appendChild(robotLocation);
      bottomDiv.appendChild(robotCard);
    });

    parent.appendChild(bottomDiv);

    return parent;
  }

  private createPopupMissionHtml(missionName: string, missionStatus: string){
    const robotMissionBox = document.createElement('div');
    robotMissionBox.classList.add(
      'flex',
      'w-full',
      'mission-status-box'
    );

    const robotMission = document.createElement('div');
    robotMission.classList.add(
      'flex',
      'flex-row',
      'items-center',
      'w-3/4'
    );
    const missionIcon = document.createElement('img');
    missionIcon.classList.add('map-icon','icon-size-4');
    missionIcon.setAttribute(
      'src',
      '/assets/icons/missions.svg'
    );

    const missionText = document.createElement('span');
    missionText.classList.add(
      'pl-1',
      'text-neutral-100',
      'font-normal',
      'text-s',
      'truncate',
      'mission-name'
    );
    missionText.innerText = missionName;
    let statusStr = getMissionStatusString(Number(missionStatus));
    const missionStatusEl = document.createElement('button');
    missionStatusEl.classList.add(
      'w-18',
      'h-6',
      'rounded-md',
      'text-white',
      'text-sm',
      'cursor-text',
      'mission-status',
      getClasses(statusStr)
    );
    missionStatusEl.innerText = statusStr;

    robotMission.appendChild(missionIcon);
    robotMission.appendChild(missionText);
    robotMissionBox.appendChild(robotMission);
    robotMissionBox.appendChild(missionStatusEl);
    return robotMissionBox;
  }
}
