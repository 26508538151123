import { Injectable } from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { clamp, get } from 'lodash';
import {
  ApiMission,
  Job,
  Mission,
  ResponseList,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { catchError, concat, map, Observable, of, ReplaySubject } from 'rxjs';
import { JobService } from './jobs.service';
import { NewJobService } from './new-job.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  public postfix = '[MISSION]';
  private jobs: Mission[];
  private playlists: JsonFile[];
  public canAbort$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private newJobService: NewJobService,
    private apiMission: ApiMission,
    private api: JobService,
    private dashboardService: DashboardService,
    private snackBarService: SnackBarService
  ) {
    this.loadPlaylist('assets/config/job-playlist.json');
  }

  private loadPlaylist(path: string) {
    fetch(path)
      .then((res) => res.json())
      .then((json) => (this.playlists = json))
      .catch((err) => console.error(err));
  }

  public play(robotId: string): Observable<PlayListStatus> {
    const playlist = this.selectPlaylist(robotId);
    if (!playlist) return of(PlayListStatus.NOFILE);

    let count = 0;
    const observables = [];

    for (let index = 0; index < playlist.repetitions; index++) {
      playlist.missions
        // postfix with the string to differentiate with adhoc jobs
        // and supply the robotId, layoutId, and mode
        .forEach((mission) => {
          observables.push({
            ...mission,
            name: `${mission.name} ${this.postfix}`,
            robotIds: [robotId],
            mode: 2,
            layoutId: playlist.layoutId,
          });
        });
    }

    // repeat the array of observables the number of times specified in the json file
    var allObservables: Observable<ResponseList<Mission>>[] = observables.map(
      (mission) => this.newJobService.newJob(mission)
    );

    return concat(...allObservables).pipe(
      map((res) => {
        count++;

        if (count === allObservables.length) return PlayListStatus.SUCCESS;
      }),
      catchError((err) => {
        return of(PlayListStatus.FAILED);
      })
    );
  }

  public stopAllJobs(robotId: string): Observable<void> {
    this.canAbort$.next(false);
    this.snackBarService.openSnackBar({
      message: 'Aborting jobs...',
      type: 'warning',
      duration: 0,
    });

    // get all active jobs from the api
    this.dashboardService.getAllActiveJobs(robotId).subscribe({
      next: (res) => {
        if (!res) {
          this.canAbort$.next(true);
          this.snackBarService.openSnackBar({
            message: 'Failed to abort jobs.',
            type: 'failed',
          });
          return;
        }
        this.jobs = res;

        // loop current active jobs to be aborted
        if (this.jobs.length > 0) {
          let count = 0;
          for (const job of this.jobs) {
            const data = {
              id: job.id,
              status: 2,
              name: job.name,
              description: job.name.toLocaleLowerCase().includes('patrol')
                ? JSON.stringify(job.description)
                : job.description,
            };

            // update active mission to abort
            this.api
              .updateMission(data)
              .subscribe((response: ResponseOne<Job>) => {
                if (response.code === 200) {
                  // success update job
                  count++;
                  if (count === this.jobs.length) {
                    this.canAbort$.next(true);
                    this.snackBarService.closeSnackBar();
                    this.snackBarService.openSnackBar({
                      message: 'Successfully abort all jobs.',
                      type: 'success',
                    });
                  }
                } else {
                  this.canAbort$.next(true);
                  this.snackBarService.closeSnackBar();
                  this.snackBarService.openSnackBar({
                    message: response.error ? response.error : 'Failed to abort all jobs.',
                    type: 'failed',
                  });
                }
              });
          }
        } else {
          this.snackBarService.openSnackBar({
            message: 'No jobs to abort.',
            type: 'failed',
          });
        }
      },
      error: (err) => {
        console.log('error', err);
      },
    });

    return of();
  }

  public isFromPlaylist(mission: any): boolean {
    const name: string = get(mission, 'name', '');
    if (name.includes(this.postfix)) {
      return true;
    }

    const condition = get(mission, 'condition', '');
    if (condition.includes(this.postfix)) {
      return true;
    }

    return false;
  }

  private selectPlaylist(robotId: string): JsonFile | undefined {
    if (this.playlists && this.playlists.length > 0)
      return this.playlists.find((rl) => rl.robotId === robotId);
  }

  public normaliseName<T>(job: T, postfix: string): T {
    const name: string = get(job, 'name');
    if (name.includes(postfix)) {
      return {
        ...job,
        name: get(job, 'name').replace(postfix, ''),
        condition: postfix,
      };
    }

    return job;
  }

  public hasPlaylist(robotId: string): boolean {
    return this.selectPlaylist(robotId) !== undefined;
  }

  private sortByCreatedAt(a: Mission, b: Mission) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }

  public sortMissionList(original: Mission[]): Mission[] {
    // first, sort missions based on the createdAt
    const missions = original.sort(this.sortByCreatedAt);

    // group missions based on status or the mode.
    // 3 === paused
    // 5 === active
    // mode = 2 === Execute after current
    const actives = [];
    const afterCurrents = [];
    const pauseds = [];
    const others = [];
    missions.forEach((mission) => {
      if (mission.status === 5) {
        actives.push(mission);
      } else if (mission.status === 3) {
        pauseds.push(mission);
      } else if (mission.mode === 2) {
        afterCurrents.push(mission);
      } else {
        others.push(mission);
      }
    });
    const missionList = [...actives, ...pauseds, ...afterCurrents, ...others];

    // normalise the missions name, e.g. remove the postfix
    return missionList.map((mission) =>
      this.normaliseName(mission, this.postfix)
    );
  }
}

interface JsonFile {
  name: string;
  repetitions: number;
  missions: Mission[];
  robotId: string;
  layoutId: string;
}
export enum PlayListStatus {
  SUCCESS,
  FAILED,
  NOFILE,
}
