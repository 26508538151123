import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { JobCreationComponent } from './job-creation.component';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { BlinkingLightComponent } from './blinking-light/blinking-light.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GotoComponent } from './goto/goto.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { MatRadioModule } from '@angular/material/radio';
import { PatrolComponent } from './patrol/patrol.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { GoChargeComponent } from './charge/go-charge.component';
import { RoutingNameComponent } from './patrol/routing-name.component';
import { ModalReplaceCurrentJobComponent } from './modal-replace-current-job/modal-replace-current-job.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DispatchComponent } from './dispatch/dispatch.component';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { GroupPatrolComponent } from './group-patrol/group-patrol.component';
import { TeleconferenceComponent } from './teleconference/teleconference.component';
import { RouteNameMediaComponent } from './patrol/route-name-media/route-name-media.component';

@NgModule({
  declarations: [
    JobCreationComponent,
    GotoComponent,
    BroadcastComponent,
    PatrolComponent,
    BlinkingLightComponent,
    GoChargeComponent,
    RoutingNameComponent,
    ModalReplaceCurrentJobComponent,
    DispatchComponent,
    GroupPatrolComponent,
    TeleconferenceComponent,
    RouteNameMediaComponent,
  ],
  exports: [
    JobCreationComponent,
    GotoComponent,
    BroadcastComponent,
    PatrolComponent,
    GoChargeComponent,
    DispatchComponent,
    GroupPatrolComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    FuseDrawerModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    ScrollingModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    FuseConfirmationModule,
  ],
})
export class JobCreationModule {}
