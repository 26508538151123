import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiLayoutMap } from 'app/services/layout.service';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import {
  ApiTrafficGraph,
  Mission,
  Robot,
  Skill,
  Vertex,
} from 'rm-api-services/dist/api-services';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { UtilityService } from 'app/services/utility.service';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CurrentMissionService } from 'app/services/current-job.service';
import { ModalReplaceCurrentJobComponent } from '../modal-replace-current-job/modal-replace-current-job.component';
import {
  RemoveBracketPipe,
  RemoveDateTimePipe,
} from 'app/shared/pipes/jobname.pipe';

@Component({
  selector: 'goto-job-creation',
  templateUrl: './goto.component.html',
  styleUrls: ['./goto.component.scss'],
})
export class GotoComponent implements OnInit, OnDestroy {
  @ViewChild('bottomOfJobCreation', { static: false })
  bottomOfJobCreationRef: ElementRef;

  @Input() layoutId: string;
  @Input() events: Observable<void>;
  @Input() skillId: string;
  @Input() robot: Robot;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();

  selectMarkers: SelectOption[] = [];
  selectedMarker: string = '';
  filteredOptions: Observable<SelectOption[]>;

  schedule: string;
  selectedStartJob: number;

  isBlinkingLightsCheck: boolean;
  blinkingLightsSkill: Skill;
  taskBlinkingLights;
  taskBlinkingLightsOff;
  isBroadcastMessageCheck: boolean;
  broadcastMessageSkill: Skill;
  taskBroadcastMessage;
  taskBroadcastMessageOff;
  ttsMessageSkill: Skill;
  taskTtsMessage;

  private currentJob: Mission;
  private currentJobName: string;
  private isConflictJob: boolean = false;
  private robotId: string;
  private subCurrentMissionServices: Subscription;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private apiLayoutMap: ApiLayoutMap,
    private apiTrafficGraph: ApiTrafficGraph,
    private dashboardSrvc: DashboardService,
    private snackBar: SnackBarService,
    private newJobSrvc: NewJobService,
    private _datePipe: DatePipe,
    private utilService: UtilityService,
    private currentMissionSrvc: CurrentMissionService,
    public dialog: MatDialog,
    private removeDateTimePipe: RemoveDateTimePipe,
    private removeBracketPipe: RemoveBracketPipe
  ) {}

  ngOnInit(): void {
    this.selectedStartJob = 5;
    // if selectedLayoutId is changed
    this.apiLayoutMap.selectedLayoutId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((layoutId) => {
        this.layoutId = layoutId;
        this.selectMarkers = [];
        this.selectedMarker = '';
        this.apiLayoutMap.selectedDestinationMarker$.next(null);
        this.getTrafficGraph().then((vertices) => {
          vertices
            .filter((vertex) =>
              this.utilService.canShowMarkerIconOnLayout(vertex.name)
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vertex) => {
              if(!vertex['lift']) {
                const jsonData = JSON.stringify({
                  layoutId: this.layoutId,
                  markerId: vertex.id,
                  name: vertex.name,
                  x: vertex.x,
                  y: vertex.y,
                  z: 0,
                });
                this.selectMarkers.push({
                  display: vertex.name,
                  value: jsonData,
                });
              }
            });
        });
      });

    this.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      //check if the current job is conflict with the current job
      //if the job is conflict open the dialog to replace the current job
      //else submit the form
      if (this.isConflictJob) {
        this.openDialog();
      } else {
        this.submitForm();
      }
    });

    // check if the robot have below skills
    this.blinkingLightsSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BLINKER-PARALLEL'
    );
    this.broadcastMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
    );
    this.ttsMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-TTS-PARALLEL'
    );

    //get the selected robotId
    this.dashboardSrvc.selectedRobotId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robotId) => {
        this.robotId = robotId;

        //get the current mission base on the current robotId
        //only subscribe to get the current mission when robotId is not null
        if (this.subCurrentMissionServices) {
          this.subCurrentMissionServices.unsubscribe();
        }
        if (this.robotId) {
          this.getCurrentMission();
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.subCurrentMissionServices) {
      this.subCurrentMissionServices.unsubscribe();
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Helper function to get traffic graph from API for selected layout
   * @returns
   */
  private getTrafficGraph(): Promise<Vertex[]> {
    return new Promise((resolve) => {
      this.apiTrafficGraph.getGraphByLayout(this.layoutId).subscribe((data) => {
        if (data.code === 200) {
          const temp = data?.result?.vertices;
          _.map(temp, (value, key) => {
            if (!value.hasOwnProperty('id')) {
              value['id'] = key;
            }
          });
          const nodeList = Object.keys(temp).map((key) => temp[key]);
          resolve(nodeList);
        }
      });
    });
  }

  /**
   * Helper function to assign selected marker from click map or dropdown
   *
   * @param event Value of selected destination
   * @param action type of action which destination is clicked. If 'map' it means new destination clicked anywhere in the map. If 'marker' its mean destination clicked in the marker.
   */
  public handleChangeMarker(
    event: SelectOption,
    action: 'map' | 'marker'
  ): void {
    const value = event.value;
    if (action === 'marker') {
      this.selectedMarker = value;
    } else if (action === 'map') {
      this.selectMarkers.push({
        display: event.display,
        value,
      });
      this.selectMarkers.sort((a, b) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      );
      this.selectedMarker = value;
    }

    this.apiLayoutMap.selectedDestinationMarker$.next(value);
  }

  selectStartJobType($event): void {
    this.selectedStartJob = $event;
  }
  jobSchedule($event): void {
    this.schedule = $event;
  }

  /**
   * Helper function to create goto job
   */
  private submitForm(): void {
    // Check if there is no destination selected
    if (!this.selectedMarker || this.selectedMarker === '') {
      this.snackBar.openSnackBar({
        message: 'Please select a destination',
        type: 'failed',
      });
      return;
    }
    // Mapping selected marker into task params object format
    const paramObj = JSON.parse(this.selectedMarker);
    const taskParam = {
      order: 0,
      skillId: this.skillId,
      params: [{ paramKey: paramObj.name, paramValue: this.selectedMarker }],
    };

    //Add all concarent tasks

    let allTask = [];

    // first add the blinking lights on, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLights);
    }

    // add broadcast on
    if (this.isBroadcastMessageCheck) {
      allTask = [...allTask, ...this.taskBroadcastMessage];
    }
    // task goto
    allTask.push(taskParam);

    if (this.isBroadcastMessageCheck) {
      // allTask.push(this.taskBroadcastMessage);
      if (this.taskBroadcastMessage[0].params[2].paramKey !== 'tts') {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params url
            this.taskBroadcastMessage[0].params[3], // params type
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      } else {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params tss message
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      }

      // allTask.push(this.taskBroadcastMessageOff);
    }

    // last, add the blinking lights off, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLightsOff);
    }
    // allTask.push(this.taskBroadcastMessage);
    // allTask.push(this.taskTtsMessage);

    // set the value of `orders` in allTask in sequence
    allTask = allTask.map((task, index) => {
      task.order = index + 1;
      return task;
    });

    // Create payload to create goto job based on payload for
    // create mission in https://docs.robotmanager.com/reference/create-a-mission
    const payload = {
      followPath: 1, // 1 = yes; 2 = no;
      mode: this.selectedStartJob,
      name:
        'GO TO ' + this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [...allTask],
      layoutId: this.layoutId,
      robotIds: [this.robot.id],
      scheduledAt: this.selectedStartJob === 3 ? this.schedule : null,
    };
    payload['description'] = paramObj.name;

      this.createGotoJob(payload);
  }

  private createGotoJob(payload) {
    //assign isSubmited = true to show loading in button
    this.newJobSrvc.isSubmited$.next(true);

    this.newJobSrvc.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: `New job added to ${this.robot.name} successfully.`,
          type: 'success',
        });
        // reset all field

        this.selectedMarker = '';
        this.apiLayoutMap.selectedDestinationMarker$.next(this.selectedMarker);
        this.closeDrawer.emit();
        this.resetType.emit();
      } else {
        this.snackBar.openSnackBar({
          message: res.error ? res.error : `Failed add new job to ${this.robot.name}`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    });
  }

  handleBlinkingLightsCheck(event) {
    this.isBlinkingLightsCheck = event.checked;
    if (this.isBlinkingLightsCheck) {
      //   const paramObj = JSON.parse(this.selectedMarker);
      // switch on the blinking lights
      const taskParam = {
        paramKey: 'switch',
        paramValue: 1,
      };
      this.taskBlinkingLights = {
        order: 0,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParam],
        mandatory: 1,
      };

      // switch off the blinking lights
      const taskParamOff = {
        paramKey: 'switch',
        paramValue: 2,
      };
      this.taskBlinkingLightsOff = {
        order: 0,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParamOff],
        mandatory: 1,
      };
    } else {
      this.taskBlinkingLights = {};
    }
    // const taskBlinker =

    //check if the current job is conflict with the current job when blinking lights is toggled
    this.isConflictJob = this.newJobSrvc.conflictJob(
      'go to',
      this.currentJob,
      this.selectedStartJob,
      this.isBroadcastMessageCheck
    );
  }

  handleBroadcastMessageCheck(event) {
    // this.isBroadcastMessageCheck = event;
    this.isBroadcastMessageCheck = event.checked;

    if (this.isBroadcastMessageCheck) {
      // const paramObj = JSON.parse(this.selectedMarker);
      // const taskParam = {
      //   paramKey: paramObj.name,
      //   paramValue: this.selectedMarker,
      // // };
      // this.taskBlinkingLights = {
      //   paramKey: paramObj.name,
      //   paramValue: this.selectedMarker,
      // };
    }

    setTimeout(() => {
      this.bottomOfJobCreationRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 200);

    //check if the current job is conflict with the current job when broadcast message is toggled
    this.isConflictJob = this.newJobSrvc.conflictJob(
      'go to',
      this.currentJob,
      this.selectedStartJob,
      this.isBroadcastMessageCheck
    );
  }

  taskDataBroadcastMessage(event) {
    this.taskBroadcastMessage = event;
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(ModalReplaceCurrentJobComponent, {
      width: '648px',
      panelClass: 'rm-dialog',
      data: { currentJobName: this.currentJobName, newJobName: 'Go to' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result === 'confirm') {
        //if the user chose confirm set the selected start job to 5
        // mode `Start now and pause current job` === 5
        this.selectedStartJob = 5;

        // submit the form
        this.submitForm();
      }
    });
  }

  //get the current job and check if the current job is conflict with the new job
  private getCurrentMission() {
    this.subCurrentMissionServices = this.currentMissionSrvc
      .getSubject(this.robot)
      // .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((missions) => {
        // check if there is a mission list
        if (missions.length > 0) {
          this.currentJob = missions?.find((mission) => mission.status === 5);

          // check if there is a current job that running
          if (this.currentJob) {
            // remove date time
            let formattedName = this.removeDateTimePipe.transform(
              this.currentJob.name
            );
            // remove brackets and text within it
            formattedName = this.removeBracketPipe.transform(formattedName);
            // transform to capitalize
            this.currentJobName =
              formattedName.charAt(0).toUpperCase() +
              formattedName.toLocaleLowerCase().slice(1);

            //check if the current job is conflict with the current job
            this.isConflictJob = this.newJobSrvc.conflictJob(
              'go to',
              this.currentJob,
              this.selectedStartJob,
              this.isBroadcastMessageCheck
            );
          }
        }
      });
  }
}
