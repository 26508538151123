import { Injectable, OnDestroy } from '@angular/core';
import { PlaylistService } from 'app/services/playlist.service';
import { get } from 'lodash';
import {
  ApiMission,
  Mission,
  MqttSettings,
} from 'rm-api-services/dist/api-services';
import { BehaviorSubject, filter, map, Subscription } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { NIL as NIL_UUID } from 'uuid';

@Injectable()
export class JobListService implements OnDestroy {
  public activeMissions$ = new BehaviorSubject<Mission[]>([]);
  public robotId$ = new BehaviorSubject<string>(null);

  private activeJobStatus: number[] = [3, 4, 5];
  private subscriptions = new Subscription();
  private intervalSub = new Subscription();

  constructor(
    private mqttSettings: MqttSettings,
    private dashboardService: DashboardService,
    private playlistService: PlaylistService,
    private apiMission: ApiMission
  ) {
    // when we receive mission update from Robot MQTT,
    // We check for the robot ID and only update the active jobs if mission ID matches.
    this.subscriptions.add(
      this.mqttSettings.socketMissionStatus$
        .pipe(
          filter((mission) => {
            const robotId = this.robotId$.value;
            const eventRobotId = get(mission, 'robotId');
            return robotId === eventRobotId;
          })
        )
        .subscribe((resp) => {
          const missionIds = this.activeMissions$.value.map(
            (mission) => mission.id
          );
          if (missionIds.includes(resp.missionId) && resp.status) {
            this.updateMissionStatus(resp.missionId, resp.status);
          }
        })
    );

    // when we receive mission from the commands that the FE submitted to the MQTT,
    // We check for the robot ID and only update the active jobs if mission ID matches.
    // This is the workaround to update the job status for paused job
    // because the MQTT /mission/status not publish message when the job is paused or newly created
    // So the front end doesnt know the job is paused if only listen to mission/status,
    // it also need to listen to MQTT /rm/mission
    this.subscriptions.add(
      this.mqttSettings.socketUserMission$
        .pipe(
          filter((mission) => {
            const robotId = this.robotId$.value;
            const eventRobotId = get(mission, 'robotId');
            return robotId === eventRobotId;
          })
        )
        .subscribe((resp) => {
          const missionIds = this.activeMissions$.value.map(
            (mission) => mission.id
          );
          if (missionIds.includes(resp.missionId)) {
            this.updateMissionStatusFromDetail(resp.missionId);
          } else {
            this.getMissionDetail(resp.missionId);
          }
        })
    );

    // when the robot ID changes, update the active jobs
    this.robotId$.subscribe((robotId) => {
      if (!robotId) {
        return;
      }

      this.refreshActiveJobs();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.intervalSub.unsubscribe();
  }

  private refreshActiveJobs() {
    const robotId = this.robotId$.value;

    this.dashboardService
      .getAllActiveJobs(robotId)
      .pipe(
        map((missions) => {
          const tempList: Mission[] = missions.map((item) => {
            let parsedDescription = item.description;

            if (
              item.name.toLowerCase().includes('patrol') &&
              item.description.trim()
            ) {
              try {
                parsedDescription = JSON.parse(
                  item.description.split('&#34;').join('"')
                );
              } catch (error) {
                console.error('Error parsing description:', error);
                // If parsing fails, retain the original description
              }
            }

            return {
              ...item,
              description: parsedDescription,
            };
          });

          return this.playlistService.sortMissionList(tempList);
        })
      )
      .subscribe((res) => this.activeMissions$.next(res));
  }

  // when we receive mission update,
  // update the active jobs
  public updateMissionStatus(id: string, status: number) {
    const missions = this.activeMissions$.value.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: status ? this.mappingMissionStatus(status) : item.status,
        };
      }
      return item;
    });

    const activeMissions = missions.filter((mission) =>
      this.activeJobStatus.includes(mission.status)
    );

    const sortedMission = this.playlistService.sortMissionList(activeMissions);
    this.activeMissions$.next(sortedMission);
  }

  private getMissionDetail(missionId: string): void {
    if (!missionId && missionId === NIL_UUID) {
      return;
    }

    this.apiMission.getById(missionId).subscribe((response) => {
      if (
        response.code === 200 &&
        response.result &&
        this.activeJobStatus.includes(response.result.status)
      ) {
        const mission = response.result;

        // Parsing the mission description to showing the route for patrol job
        let parsedDescription = mission.description;
        if (
          mission.name.toLowerCase().includes('patrol') &&
          mission.description.trim()
        ) {
          try {
            parsedDescription = JSON.parse(
              mission.description.split('&#34;').join('"')
            );
          } catch (error) {
            console.error('Error parsing description:', error);
            // If parsing fails, retain the original description
          }
        }
        mission.description = parsedDescription;

        const currentMissions = [mission, ...this.activeMissions$.value];

        // It uses a Map to store unique objects based on the specified property (missionId).
        // Finally, it converts the map values back to an array,
        // resulting in an array without duplicate objects.
        const uniqueMap = new Map();
        currentMissions.forEach((item) => {
          const key = item['id'];
          uniqueMap.set(key, item);
        });
        const uniqueMissions = Array.from(uniqueMap.values());

        const sortedMission =
          this.playlistService.sortMissionList(uniqueMissions);
        this.activeMissions$.next(sortedMission);
      }
    });
  }

  /**
   * Mapping the mission status from MQTT data. Because mission status is difference
   * that get from the API and MQTT.
   * https://docs.robotmanager.com/docs/server-clientrobot-communication#send-mission-status-to-robotmanager-robotmissionstatuscompanyid
   *
   * @param missionStatus Mission status from MQTT
   * @returns Mission status with API formatted
   */
  private mappingMissionStatus(missionStatus: number): number {
    let status = missionStatus;

    switch (missionStatus) {
      case 1: // Mission active
      case 6: // Mission request path plan
        status = 5;
        break;
      case 2: // Mission complete
        status = 1;
        break;
      case 3: // Mission failed
        status = 6;
        break;
      case 4: // Mission cancelled
        status = 2;
        break;
    }

    return status;
  }

  private updateMissionStatusFromDetail(missionId: string): void {
    if (!missionId && missionId === NIL_UUID) {
      return;
    }

    this.apiMission.getById(missionId).subscribe((response) => {
      if (response.code === 200 && response.result) {
        const mission = response.result;
        const missions = this.activeMissions$.value.map((item) => {
          if (item.id === mission.id) {
            return {
              ...item,
              status: mission.status,
            };
          }
          return item;
        });

        const activeMissions = missions.filter((mission) =>
          this.activeJobStatus.includes(mission.status)
        );

        const sortedMission =
          this.playlistService.sortMissionList(activeMissions);
        this.activeMissions$.next(sortedMission);
      }
    });
  }
}
